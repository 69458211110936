import { BaseEntity } from '@/libs/api-request'

export interface BaseConfig extends BaseEntity {
  /** 配置名称，如：数据库端口 */
  configAlia?: string;
  /** 实例配置类型 */
  configType?: BaseConfigType;
  /** 配置key */
  configKey?: string;
  /** 配置value是否允许为空 默认为true */
  valueNullable?: boolean;
  /** 配置值 */
  configValue?: string;
  /** 是否核心配置 默认为false */
  kernel?: boolean;
  /** 系统配置项，如果为true，则该配置在页面上不显示或者不允许用户修改 默认为false */
  cantModi?: boolean;
  /** 配置是否可以删除 */
  canDelete?: boolean;
  /** 是否显示隐藏数据，用于密码等处理 */
  hiddenValue?: boolean;
  /** 当前值在部署前一定要修改 */
  mustModi?: boolean;
  /** 判断是否为系统默认配置 */
  systemDefault?: boolean;
  /** 配置说明 */
  memo?: string;
  /** 当前配置项的数据类型 */
  dataType?: string;
  /** 配置项是否是key=value的类型，如果是则configKey要赋值，否，只需要给configValue赋值 */
  hasKey?: boolean;
  /** 约束的数据类型，如果为空使用configKey指定的类型 */
  constraintDataType?: string;

  configTypeCode?: string;
}

/** 配置类型枚举 */
export enum BaseConfigType {
  /** 源image */
  FROM,
  /** 端口 */
  PORT,
  /** 挂载目录 */
  VOLUME_DIR,
  /** 挂载文件 */
  VOLUME_FILE,
  /** arg配置 */
  ARG,
  /** 容器环境变量 */
  ENV,
  /** 配置文件 */
  CONFIGFILE, // 未知，暂时保留
  /** 目录配置 */
  CONFIGDIR, // 未知，暂时保留
  /** 命令 */
  CMD,
  /** 运行 */
  RUN,
  /** 资源配置 */
  RESOURCE, // 未知，暂时保留
  /** 入口点 */
  ENTRYPOINT,
  /** 网络配置 */
  NET,
  /** 网络模式 */
  NETWORKMODE,
  /** 工作目录 */
  WORKDIR,
  /** 容器是否有权限操作 */
  PRIVILEGED,
  /** UNSETENV */
  UNSETENV,
  /** 镜像环境变量 */
  ENV_DOCKERFILE,
  /** ADD指令 */
  ADD,
  /** COPY指令 */
  COPY,
  /** Dockerfile中的挂载 */
  VOLUME_DOCKERFILE,
  /** 如果已经存在容器，是否删除，默认不删除 */
  RM_EXISTS,
  /** 重启策略 */
  RESTART_POLICY,
  /** 是否在启动完毕后或者退出后，马上移除容器 */
  REMOVE,
  /** 指定cpu，如：0-2或者0,1,2   表示指定0,1,2编号cpu执行 */
  CPUSET,
  /** 容器运行最大内存 */
  MEMORY_LIMIT,
  /** 是否开启控制台日志 */
  TERMINAL,
  /** 启动后监控日志的最长时间 如果为空则一直监控 */
  LOG_FLOWER_SECOND,
  /** arg的打包配置 */
  BUILDARG,
  /** 容器日志最大值 */
  LOG_MAX_SIZE
}

/** 配置类型详细信息 */
class AppInstanceConfigDetailType {
  /** 端口 */
  PORT = {
    kernel: false,
    valueNullable: false,
    hasKey: true,
    canDelete: true,
    cantModi: false,
    dataType: 'integer',
    configType: 1
  } as BaseConfig

  /** 目录挂载 */
  VOLUME_DIR = {
    kernel: false,
    valueNullable: false,
    canDelete: true,
    cantModi: false,
    hasKey: true,
    dataType: 'path',
    configType: 2
  } as BaseConfig

  /** 文件挂载 */
  VOLUME_FILE = {
    kernel: false,
    valueNullable: false,
    hasKey: true,
    canDelete: true,
    cantModi: false,
    dataType: 'file',
    configType: 3
  } as BaseConfig

  /** 镜像ARG变量 */
  ARG = {
    kernel: false,
    valueNullable: false,
    hasKey: true,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 4
  } as BaseConfig

  /** 容器环境变量 */
  ENV = {
    kernel: false,
    valueNullable: false,
    hasKey: true,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 5
  } as BaseConfig

  /** 命令 */
  CMD = {
    kernel: false,
    valueNullable: false,
    canDelete: true,
    cantModi: false,
    hasKey: false,
    dataType: 'string',
    configType: 8
  } as BaseConfig

  /** 镜像RUN指令 */
  Run = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 9
  } as BaseConfig

  /** 镜像ENTRYPOINT */
  ENTRYPOINT = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 11
  } as BaseConfig

  /** 网络配置 */
  NET = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 12
  } as BaseConfig

  /** 工作目录 */
  WORKDIR = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 14
  } as BaseConfig

  /** 是否拥有主机权限 */
  PRIVILEGED = {
    kernel: false,
    valueNullable: false,
    canDelete: true,
    cantModi: false,
    hasKey: false,
    dataType: 'boolean',
    configType: 15
  } as BaseConfig

  /** 镜像环境变量 */
  ENV_DOCKERFILE = {
    kernel: false,
    valueNullable: false,
    hasKey: true,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 17
  } as BaseConfig

  /** 镜像ADD指令 */
  ADD = {
    kernel: false,
    valueNullable: false,
    hasKey: true,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 18
  } as BaseConfig

  /** 镜像COPY指令 */
  COPY = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 19
  } as BaseConfig

  /** 如果已经存在容器，是否删除，默认不删除 */
  RM_EXISTS = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'boolean',
    configType: 21
  } as BaseConfig

  /** 重启策略 */
  RESTART_POLICY = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'string',
    configType: 22
  } as BaseConfig

  /** 是否在启动完毕后或者退出后，马上移除容器 */
  REMOVE = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'boolean',
    configType: 23
  } as BaseConfig

  /** 指定cpu，如：0-2或者0,1,2   表示指定0,1,2编号cpu执行 */
  CPUSET = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'integer',
    configType: 24
  } as BaseConfig

  /** 容器运行最大内存 */
  MEMORY_LIMIT = {
    kernel: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    valueNullable: false,
    dataType: 'integer',
    configType: 25
  } as BaseConfig

  /** 是否开启控制台日志 */
  TERMINAL = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'boolean',
    configType: 26
  } as BaseConfig

  /** 启动后监控日志的最长时间 如果为空则一直监控 */
  LOG_FLOWER_SECOND = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'integer',
    configType: 27
  } as BaseConfig

  /** 容器日志最大值 */
  LOG_MAX_SIZE = {
    kernel: false,
    valueNullable: false,
    hasKey: false,
    canDelete: true,
    cantModi: false,
    dataType: 'integer',
    configType: 29
  } as BaseConfig
}
export default new AppInstanceConfigDetailType()
