
import { Prop, Component, Vue } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import appInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import ConfigCenterApi from '@/api/config-center/config-center'
import AppInstanceConfigApi from '@/api/maintain/app-instance-config'
import { BaseConfigType } from '@/api/maintain/base-config'

@Component({
  name: 'InitConsulModal'
})
export default class InitConsulModal extends Vue implements BaseModal {
  @Prop({
    type: Object,
    required: true,
    default: undefined
  })
  readonly instance!: AppInstance

   visiable=false

   loading=false

   currentStep=0

   status='wait'

  superAdminToken=''

  xbgManagerToken=''

  xbgAppToken=''

  /// 查询url
  consulApi!: ConfigCenterApi

  created () {
    /// 请求port
    AppInstanceConfigApi.query({ page: 1, pageSize: 10, sorts: [], appInstanaceId: this.instance?.id, configType: BaseConfigType.PORT, configAlia: location.protocol === 'http:' ? 'http服务端口' : 'https服务端口' }).then((res) => {
      this.consulApi = new ConfigCenterApi(`${location.protocol}//${this.instance.pcServerIpAddress}:${res.data?.items[0].configValue || ''}`)
      this.getExtData()
    })
  }

  show (): void {
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  doSave (): void {
    this.close()
  }

  doCancel (): void {
    this.close()
  }

  copy (str: string) {
    (this as any).$clipboard(str)
    this.$Message.success('token复制成功')
  }

  startInit () {
    this.loading = true
    this.status = 'process'
    this.consulApi.initSuperAdmin().then(async (res: any) => {
      this.superAdminToken = res.data.SecretID
      this.currentStep = 1
      this.status = 'process'
      this.saveExtData()
      await this.configXbgManger()
      this.saveExtData()
      this.currentStep = 2
      this.status = 'process'
      await this.configXbgApp()
      this.saveExtData()
      this.status = 'finish'
      this.loading = false
    }).catch((err) => {
      this.status = 'error'
      this.$Message.error('超管初始化失败，' + err.message)
      this.loading = false
    })
  }

  /** 配置xbg管理token */
  async configXbgManger () {
    if (!this.superAdminToken?.match(/\S/)) {
      return
    }
    const policy = await this.consulApi.createPolicy({
      Name: 'xbgManager',
      Rules: `service_prefix "" {
  policy = "read"
}
node_prefix "" {
  policy = "write"
}
key_prefix "" {
  policy = "write"
}`
    }, this.superAdminToken)
    const res = await this.consulApi.createToken({
      Description: 'xbg管理token',
      Policies: [
        {
          ID: (policy as any).selectData.ID
        }
      ],
      Local: false
    }, this.superAdminToken)
    this.xbgManagerToken = (res as any).data.SecretID
  }

  /** 配置xbg管理token */
  async configXbgApp () {
    if (!this.superAdminToken?.match(/\S/)) {
      return
    }
    const policy = await this.consulApi.createPolicy({
      Name: 'xbgApp',
      Rules: `service_prefix "" {
  policy = "read"
}
node_prefix "" {
  policy = "read"
}
key_prefix "" {
  policy = "read"
}`
    }, this.superAdminToken)
    const res = await this.consulApi.createToken({
      Description: 'xbg管理token',
      Policies: [
        {
          ID: (policy as any).data.ID
        }
      ],
      Local: false
    }, this.superAdminToken)
    this.xbgAppToken = (res as any).data.SecretID
  }

  /** 保存实例扩展数据 */
  saveExtData () {
    if (this.instance.id) {
      appInstanceApi.saveInstanceExtData(this.instance.id, {
        superAdminToken: this.superAdminToken,
        xbgManagerToken: this.xbgManagerToken,
        xbgAppToken: this.xbgAppToken
      })
    }
  }

  /** 获取实例扩展数据 */
  async getExtData () {
    if (this.instance.id) {
      const re = await appInstanceApi.queryInstanceExtData(this.instance.id)
      this.superAdminToken = re.data?.superAdminToken || ''
      this.xbgManagerToken = re.data?.xbgManagerToken || ''
      this.xbgAppToken = re.data?.xbgAppToken || ''
      if (this.superAdminToken) {
        this.currentStep = 1
      }
      if (this.xbgManagerToken) {
        this.currentStep = 2
      }
      if (this.xbgAppToken) {
        this.currentStep = 3
        this.status = 'finish'
      }
    }
  }
}

