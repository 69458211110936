
import ConfigCenterMenu from './templete/config-center-menu.vue'
import ConfigCenterHeader from './templete/config-center-header.vue'
import ConfigCenterEditor from './templete/config-center-editor.vue'
import { Vue, Component } from 'vue-property-decorator'
import ConfigCenterApi from '@/api/config-center/config-center'
import appInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import AppInstanceConfigApi from '@/api/maintain/app-instance-config'
import { BaseConfigType } from '@/api/maintain/base-config'

@Component({
  name: 'ConfigCenterMain',
  components: { ConfigCenterMenu, ConfigCenterHeader, ConfigCenterEditor }
})
export default class ConfigCenterMain extends Vue {
  /// 注册服务ip
  pcServerIpAddress = ''
  // 注册服务端口
  port=''
  /// 配置文件名称
  configDataName=''
  consulApi!: ConfigCenterApi
  /// 实例扩展数据
  instanceExtData = {} as Record<string, string>
  // 当前实例
  curInsance={} as AppInstance
  /// 新数据
  newData = ''
  /// 旧数据
  oldData = ''

  /** 所选实例的xbgManagerToken */
  xbgManagerToken=''

  /// 选择的注册中心实例
  consulServerChange (val: AppInstance): void {
    this.curInsance = val
    appInstanceApi.queryInstanceExtData(val.id!).then((res) => {
      this.xbgManagerToken = res.data?.xbgManagerToken || ''
      this.instanceExtData = res.data
      /// 请求port
      AppInstanceConfigApi.query({ page: 1, pageSize: 10, sorts: [], appInstanaceId: val?.id, configType: BaseConfigType.PORT, configAlia: location.protocol === 'http:' ? 'http服务端口' : 'https服务端口' }).then((res) => {
        if (!res.data || res.data.items.length < 1) {
          this.$Message.error(`当前consul暂未配置${location.protocol === 'http:' ? 'http' : 'https'}端口`)
          return
        }
        this.port = res.data?.items[0].configValue || ''
        this.pcServerIpAddress = val.pcServerIpAddress
        this.consulApi = new ConfigCenterApi(`${location.protocol}//${this.pcServerIpAddress}:${this.port}`)
      })
    })
  }

  /// 选择配置文件
  selectConfigData (val: string): void {
    this.configDataName = val
  }

  /// 获取新数据
  getNewData (newData: string, oldData: string): void {
    this.newData = newData
    this.oldData = oldData
  }
}
