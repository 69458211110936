
import { Vue, Component } from 'vue-property-decorator'
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import { getterUserInfo, updateConfigCenterInstance, getterConfigCenterInstance } from '@/store'
import showInitConsulModal from './init-consul-modal'
@Component({
  name: 'ConfigCenterHeader'
})
export default class ConfigCenterHeader extends Vue {
  // 注册中心实例选项
  apiInstance = [] as AppInstance[]

  curInstanceData={} as AppInstance

  timer: any=undefined

  canInitToken=true

  get userInfo () {
    return getterUserInfo()
  }

  get canEidt () {
    return this.userInfo?.canOperateRegisterCenter
  }

  created () {
    AppInstanceApi.listAppInstanceByImageType(1).then((response) => {
      this.apiInstance = response.data || []

      if (getterConfigCenterInstance()?.id) {
        this.curInstanceData = getterConfigCenterInstance()
        this.onChange(this.curInstanceData.id)
      }
    })
  }

  initConsul () {
    showInitConsulModal(this.curInstanceData)
  }

  onChange (id: any): void {
    AppInstanceApi.queryInstanceExtData(id).then((res) => {
      this.curInstanceData = this.apiInstance.find((item) => item.id === id) || {} as AppInstance
      // 缓存
      updateConfigCenterInstance(this.curInstanceData)
      if (res.data?.xbgAppToken?.match(/\S/)) {
        this.canInitToken = false
      }
      this.$emit('onChange', this.curInstanceData)
    })
  }
}
