
import ConfigCenterApi from '@/api/config-center/config-center'
import { AppInstance } from '@/api/maintain/app-instance'
import { DropdownItem } from 'view-design'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import showCreateKeyPolicyModal from './create-key-policy'
@Component({
  name: 'ProjectMenu',
  components: { DropdownItem }
})
export default class ProjectMenu extends Vue {
  @Prop({
    type: String,
    required: false
  })
  newData = ''

  @Prop({
    type: String,
    required: false
  })
  oldData = ''

  @Prop({
    type: Object,
    required: false,
    default: {}
  })
  curInstance!: AppInstance

  /// 新增应用名称
  applicationName = ''
  // /// 下拉菜单
  menuList: {
    title: string;
    name: string;
    expend: boolean;
    contextmenu: boolean;
    children?: any[];
  }[] = []

  /// 新增配置名称
  configData = ''
  /// 被选中的配置文件
  selectConfigData = ''
  /// 查询参数
  searchValue = ''

  /// 配置服务名
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  pcServerIpAddress!: string

  /// 配置服务端口
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  port!: string

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  xbgManagerToken!: string

  @Prop({
    type: Object,
    required: false,
    default: () => { return {} }
  })
  instanceExtData!: Record<string, string>

  /// 查询url
  consulApi!: ConfigCenterApi
  /// 树节点参数
  contextData!: any
  /// 展示模态框
  modal = false
  /// 父文件
  file = ''
  /// 新树子节点
  newList!: {
    title: string;
    name: string;
    expend: boolean;
    contextmenu: boolean;
    children?: any[];
  }

  /// 查询配置文件过滤器
  filterApplication (): void {
    if (this.searchValue !== '') {
      this.menuList = []
      this.consulApi.selectList('configs/', this.xbgManagerToken).then((res) => {
        res.forEach((item: string) => {
          if (item.startsWith('configs/' + this.searchValue)) {
            this.menuList.push({
              title: item.slice(8, item.length - 1),
              name: item,
              expend: true,
              contextmenu: true,
              children: this.selectData('configs/' + this.searchValue + '/')
            })
          }
        })
      })
    } else {
      this.selectApplication()
    }
  }

  /// 查询应用列表
  @Watch('pcServerIpAddress')
  selectApplication (): void {
    this.consulApi = new ConfigCenterApi(`${location.protocol}//${this.pcServerIpAddress}:${this.port}`)
    this.menuList = this.selectData('configs/')
  }

  /** 设置权限模态框 */
  showSetNodeRightModal (key: string) {
    showCreateKeyPolicyModal(this.curInstance, key)
  }

  /// 递归菜单
  selectData (name: string): any[] {
    const treelist: any[] = []
    this.consulApi.selectList(name, this.xbgManagerToken).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        const item = res.data[i]
        if (item.endsWith('/') && item.length !== name.length) {
          treelist.push({
            title: item.slice(name.length, item.length - 1),
            name: item + 'data',
            expend: true,
            children: this.selectData(item),
            render: this.folderRender
          })
        }
      }
    })
    return treelist
  }

  /// 更新面包屑
  selectMenuItem (name: string): void {
    this.selectConfigData = name
    this.$emit('selectMenuItem', name)
  }

  /// 切换树节点
  onSelectChange (data: any): void{
    if (this.oldData !== this.newData) {
      this.$Modal.confirm({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (h: any) => {
          return h('span', [
            '该配置文件未保存，是否保存更改？'
          ])
        },
        onOk: () => {
          this.saveValue(this.selectConfigData, this.newData)
          this.selectMenuItem(data[0].name)
        },
        onCancel: () => {
          this.selectMenuItem(data[0].name)
        }
      })
    } else {
      this.selectMenuItem(data[0].name)
    }
  }

  /// 保存配置文件
  saveValue (configDataName: string, value: string): void {
    this.consulApi.saveConfigData(configDataName, value, this.xbgManagerToken)
      .then((res) => {
        if (res) {
          this.$Message.success('保存成功')
        } else {
          this.$Message.error('保存失败')
        }
      }).catch((ex) => {
        this.$Message.error('保存失败' + ex)
      })
  }

  ok () {
    this.consulApi.addNewValue(this.file + this.applicationName, this.xbgManagerToken).then((res) => {
      if (res) {
        this.$Message.success('新增成功')
      } else {
        this.$Message.error('新增失败')
      }
    }).catch((ex) => {
      this.$Message.error('新增失败' + ex.Message)
    }).finally(() => {
      if (this.file === 'configs/') {
        this.selectApplication()
      } else {
        this.newList = this.newChild(this.file, this.applicationName)
        this.menuList = this.updateMenu(this.file, this.applicationName, this.menuList)
      }
      this.applicationName = ''
    })
  }

  cancel () {
    this.applicationName = ''
    this.modal = false
  }

  /// 展示模态框
  showModal (val?: number) {
    if (val === 1) {
      this.file = 'configs/'
      this.modal = true
    } else {
      this.file = this.contextData.name
      this.modal = true
    }
  }

  /// 添加新的子节点
  newChild (fatherRoute: string, name: string): any {
    let item: any = {}
    if (name.indexOf('/') !== -1) {
      item = {
        title: name.slice(0, name.indexOf('/')),
        name: fatherRoute + name.slice(0, name.indexOf('/') + 1),
        expend: true,
        children: []
      }
      item.children.push(this.newChild(fatherRoute + name.slice(0, name.indexOf('/') + 1), name.slice(name.indexOf('/') + 1, name.length)))
    } else {
      item = {
        title: name,
        name: fatherRoute + name + '/',
        expend: true,
        children: []
      }
      item.children.push({ name: fatherRoute + name + '/data', title: 'data', expend: false })
    }
    return item
  }

  /// 删除应用
  deleteApplication (): void {
    this.$Modal.confirm({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (h: any) => {
        return h('span', [
          '是否要删除该文件？'
        ])
      },
      onOk: () => {
        this.consulApi.deleteApplication(this.contextData.name, this.xbgManagerToken).then((res) => {
          if (res) {
            this.$Message.success('删除成功')
          } else {
            this.$Message.error('删除失败')
          }
        }).catch((ex) => {
          this.$Message.error('删除失败' + ex.Message)
        }).finally(() => {
          this.selectConfigData = ''
          this.selectMenuItem(this.selectConfigData)
          this.selectApplication()
        })
      }
    })
  }

  /// 更新树
  updateMenu (fatherRoute: string, name: string, menuList: any): any[] {
    for (let i = 0; i < menuList.length; i++) {
      const item = menuList[i]
      if (fatherRoute.startsWith(item.name)) {
        if (fatherRoute === item.name) {
          let flag = true
          for (let j = 0; j < menuList[i].children.length; j++) {
            if (menuList[i].children[j].name === this.newList.name) {
              menuList[i].children[j] = this.newList
              flag = false
              break
            }
          }
          if (flag) {
            menuList[i].children?.push(this.newList)
          }
          return menuList
        } else {
          menuList[i].children = this.updateMenu(fatherRoute, name, menuList[i].children)
        }
      }
    }
    return menuList
  }

  /// 获取树节点
  handleContextMenu (data: any) {
    this.contextData = data
  }

  /// 树节点样式
  paperRender (h: any, { data }: {data: any}) {
    return h('span', {
      style: {
        display: 'inline-block',
        width: '100%'
      }
    }, [
      h('div', {
        style: {
          height: '23px'
        }
      }, [
        h('Tooltip', {
          props: {
            content: data.title,
            maxWidth: '100px'
          }
        }, [
          h('span', {
            style: {
              display: 'inline-block',
              width: '65px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }, [
            h('Icon', {
              props: {
                type: 'ios-paper-outline'
              },
              style: {
                marginRight: '8px'
              }
            }),
            data.title
          ]),
          h('Icon', {
            props: {
              type: 'md-trash'
            },
            style: {
              display: 'inline-block',
              marginLeft: '25px'
            }
          })
        ])
      ])
    ])
  }

  folderRender (h: any, { data }: {data: any}) {
    return h('span', {
      style: {
        display: 'inline-block',
        width: '100%'
      }
    }, [
      h('div', {
        style: {
          height: '23px'
        }
      }, [
        h('Tooltip', {
          props: {
            content: data.title,
            maxWidth: '100px'
          }
        }, [
          h('span', {
            style: {
              display: 'inline-block',
              width: '65px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }, [
            h('Icon', {
              props: {
                type: 'ios-folder-outline'
              },
              style: {
                marginRight: '8px'
              }
            }),
            data.title
          ]),
          h('Tooltip', {
            props: {
              theme: 'light',
              transfer: true,
              placement: 'top'
            }
          }, [
            h('Icon', {
              props: {
                type: 'ios-more'
              },
              style: {
                display: 'inline-block',
                marginLeft: '25px'
              }
            }),
            h('div', {
              slot: 'content',
              style: {
                display: 'flex',
                alignItems: 'center'
              }
            }, [
              h('icon', {
                props: {
                  type: 'ios-key',
                  size: '18'
                },
                style: {
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    this.showSetNodeRightModal(data.name.substring(0, data.name.length - 5).replace(/[/]/g, '_'))
                  }
                }
              }),
              h('icon', {
                props: {
                  type: 'ios-create',
                  size: '18'
                },
                style: {
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    this.contextData = data
                    this.showModal()
                  }
                }
              }),
              h('icon', {
                props: {
                  type: 'md-trash',
                  size: '18'
                },
                style: {
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    this.contextData = data
                    this.deleteApplication()
                  }
                }
              })
            ])
          ])
        ])
      ])
    ])
  }
}
