import { requestRaw } from '@/libs/http-request'
import axios from 'axios'

export default class ConfigCenterApi {
  consulServerUrl!: string

  constructor (consulServerUrl: string) {
    this.consulServerUrl = consulServerUrl
  }

  /** 初始化超管用户 */
  initSuperAdmin () {
    return axios.request({
      url: `${this.consulServerUrl}/v1/acl/bootstrap`,
      method: 'PUT'
    })
  }

  /** 创建权限规则 */
  createPolicy (policy: Record<string, string>, token: string) {
    return axios.request({
      url: `${this.consulServerUrl}/v1/acl/policy`,
      method: 'PUT',
      data: policy,
      headers: {
        authorization: 'Bearer ' + token
      }
    })
  }

  /** 创建token */
  createToken (tokenData: Record<string, any>, token: string) {
    return axios.request({
      url: `${this.consulServerUrl}/v1/acl/token`,
      method: 'PUT',
      data: tokenData,
      headers: {
        authorization: 'Bearer ' + token
      }
    })
  }

  /// 删除应用
  deleteApplication (data: string, token: string): Promise<boolean> {
    return axios.request({
      url: `${this.consulServerUrl}/v1/kv/${data}?dc=dc1&recurse`,
      method: 'DELETE',
      headers: token.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }

  /// 删除配置文件
  deleteConfigData (data: string, token: string): Promise<boolean> {
    return axios.request({
      url: `${this.consulServerUrl}/v1/kv/${data}`,
      method: 'DELETE',
      headers: token.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }

  /// 新增配置文件
  addNewValue (data: string, token: string): Promise<boolean> {
    return axios.request({
      url: `${this.consulServerUrl}/v1/kv/${data}/data`,
      method: 'PUT',
      headers: token.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }

  /// 保存配置数据
  saveConfigData (data: string, value: string, token: string): Promise<boolean> {
    return axios.request({
      url: `${this.consulServerUrl}/v1/kv/${data}`,
      method: 'PUT',
      data: value,
      headers: token.match(/\S/) ? {
        'content-type': 'application/json; charset=UTF-8'
      } : { 'content-type': 'application/json; charset=UTF-8', authorization: 'Bearer ' + token }
    })
  }

  /// 查询配置数据
  selectConfigData (data: string, token: string): Promise<any> {
    return axios.request({
      url: `${this.consulServerUrl}/v1/kv/${data}`,
      method: 'GET',
      headers: token.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }

  /// 查询应用列表
  selectList (name: string, token: string): Promise<any> {
    return axios.request({
      url: `${this.consulServerUrl}/v1/kv/${name}?keys&separator=%2F`,
      method: 'GET',
      headers: token.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }

  /// 查询应用下的配置数据列表
  selectConfigDataList (data: string, token: string): Promise<any> {
    return axios.request({
      url: `${this.consulServerUrl}/v1/kv/${data}?keys`,
      method: 'GET',
      headers: token.match(/\S/) ? {
        authorization: 'Bearer ' + token
      } : undefined
    })
  }
}
