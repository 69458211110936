
import CodeMirror, { EditorFromTextArea } from 'codemirror'
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/lint/lint.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/mode/yaml/yaml.js'
import 'codemirror/addon/lint/yaml-lint'
import 'codemirror/addon/selection/active-line'

@Component({
  name: 'BaseYamlEditor'
})
export default class BaseYamlEditor extends Vue {
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  configDataName!: string

  @Prop({
    type: String,
    required: false,
    default: '@'
  })
  yamlValue!: string

  @Ref()
  textarea!: HTMLTextAreaElement

  /// yaml编辑框
  yamlEditor!: EditorFromTextArea

  /// yaml样式定义
  options = {
    // 缩进格式
    tabSize: 2,
    // 主题，对应主题库 JS 需要提前引入
    theme: 'monokai',
    // 行号码
    lineNumbers: true,
    line: true,
    gutters: ['CodeMirror-lint-markers'],
    // 光标背景行高亮
    styleActiveLine: true,
    // 自动刷新
    autoRefresh: true
  }

  @Watch('yamlValue')
  @Watch('configDataName')
  changeValue (): void {
    this.yamlEditor.setValue(this.yamlValue)
  }

  mounted (): void {
    this.yamlEditor = CodeMirror.fromTextArea(this.textarea, this.options)
    this.yamlEditor.setValue(this.textarea.value)
    this.yamlEditor.on('change', this.saveValue)
  }

  /// 保存数据
  saveValue (): void {
    this.$emit('change', this.yamlEditor.getValue())
  }

  /// 重置数据
  resetValue (yamlValue: string): void {
    this.yamlEditor.setValue(yamlValue)
  }
}

