/**
 * 实例
 */

import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult } from '@/libs/http-request'
import { BaseConfig } from './base-config'

/** 实例配置 */
export interface AppInstanceConfig extends BaseConfig {
  /** 应用实例id */
  appInstanaceId?: number;
  /** 对应的teamId */
  teamId?: number;
}

/**
 * 实例服务请求api接口
 */
class AppInstanceConfigApi extends DefaultApiService<AppInstanceConfig> {
  constructor () {
    super('/api/maintain/appinstanceconfigs')
  }

  /**
    * 取得对象实例下所有实例配置
    * @param id 应用id
    */
  public queryByInstanceId (id: number): Promise<DataApiResult<AppInstanceConfig[]>> {
    return request({
      url: this.baseUrlPath + `queryAllByInstanceId/${id}`,
      method: 'POST'
    })
  }

  /**
   * 修改对应对象的configValue字段
   * @param configId
   * @param configValue
   * @return
   */
  public updateValueById (configId: number, configValue: string) {
    return request({
      url: this.baseUrlPath + `updateConfigValue/${configId}/${configValue}`,
      method: 'GET'
    })
  }
}

export default new AppInstanceConfigApi()
