
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ConfigCenterApi from '@/api/config-center/config-center'
import BaseYamlEditor from './yaml-editor-templete.vue'
import { Base64 } from 'js-base64'

@Component({
  name: 'configCenterEditor',
  components: { BaseYamlEditor }
})
export default class ConfigCenterEditor extends Vue {
  /// 配置文件名称
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  configDataName!: string

  @Prop({
    type: ConfigCenterApi,
    required: false
  })
  consulApi!: ConfigCenterApi

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  xbgManagerToken!: string

  /// 加载状态
  spinShow = false

  /// 旧数据
  yamlValue = ''

  /// 新数据
  yamlNewData = ''

  /// 面包屑
  breadCrumbList: {name: string}[] = []

  created (): void {
    this.showYaml()
  }

  /// 显示编辑框
  @Watch('configDataName')
  showYaml (): void {
    this.spinShow = true
    this.breadCrumbList = []
    this.breadCrumbList.push({ name: this.configDataName })
    this.consulApi.selectConfigData(this.configDataName, this.xbgManagerToken).then((res) => {
      if (res[0].Value === null) {
        this.yamlValue = ''
      } else {
        this.yamlValue = Base64.decode(res[0].Value)
      }
    }).catch((ex) => {
      this.$Message.error('加载失败' + ex)
    }).finally(() => {
      this.spinShow = false
    })
  }

  /// 保存配置文件
  saveValue (configDataName: string, yamlNewData: string): void {
    this.consulApi.saveConfigData(configDataName, yamlNewData, this.xbgManagerToken).then((res) => {
      this.spinShow = true
      if (res) {
        this.$Message.success('保存成功')
      } else {
        this.$Message.error('保存失败')
      }
    }).catch((ex) => {
      this.$Message.error('保存失败' + ex)
    }).finally(() => {
      this.spinShow = false
      this.yamlValue = this.yamlNewData
    })
  }

  /// 修改数据
  changeData (value: string): void {
    this.yamlNewData = value
    this.$emit('newData', this.yamlNewData, this.yamlValue)
  }

  /// 重置提示框
  resetTip (): void {
    this.$Modal.confirm({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (h: any) => {
        return h('span', [
          '是否要重置该配置文件'
        ])
      },
      onOk: () => {
        this.consulApi.selectConfigData(this.configDataName, this.xbgManagerToken).then((res) => {
          if (res[0].Value === null) {
            this.yamlValue = ''
          } else {
            this.yamlValue = Base64.decode(res[0].Value)
          }
          (this.$refs.baseYamlEditor as BaseYamlEditor).resetValue(this.yamlValue)
        })
      }
    })
  }
}
