
import { Prop, Component, Vue } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'

import appInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import ConfigCenterApi from '@/api/config-center/config-center'
import AppInstanceConfigApi from '@/api/maintain/app-instance-config'
import { BaseConfigType } from '@/api/maintain/base-config'

@Component({
  name: 'InitConsulModal'
})
export default class InitConsulModal extends Vue implements BaseModal {
  @Prop({
    type: Object,
    required: true,
    default: undefined
  })
  readonly instance!: AppInstance;

  @Prop({
    type: String,
    required: true,
    default: undefined
  })
  readonly key!: string;

  visiable = false;

  loading = false;

  currentStep = 0;

  status = 'wait';

  extData = {} as Record<string, string>;

  readToken = '';

  writeToken = '';

  /// 查询url
  consulApi!: ConfigCenterApi;

  created () {
    /// 请求port
    AppInstanceConfigApi.query({
      page: 1,
      pageSize: 10,
      sorts: [],
      appInstanaceId: this.instance?.id,
      configType: BaseConfigType.PORT,
      configAlia: location.protocol === 'http:' ? 'http服务端口' : 'https服务端口'
    }).then((res) => {
      this.consulApi = new ConfigCenterApi(
        `${location.protocol}//${this.instance.pcServerIpAddress}:${
          res.data?.items[0].configValue || ''
        }`
      )
      this.getExtData()
    })
  }

  show (): void {
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  doSave (): void {
    this.close()
  }

  doCancel (): void {
    this.close()
  }

  copy (str: string) {
    (this as any).$clipboard(str)
    this.$Message.success('token复制成功')
  }

  async startInit () {
    this.loading = true
    this.status = 'process'
    this.readToken = await this.configNodeRight(this.key, 'read')
    this.currentStep = 1
    this.writeToken = await this.configNodeRight(this.key, 'write')
    this.status = 'finish'
    this.loading = false
  }

  /** 配置xbg管理token */
  async configNodeRight (key: string, right: string) {
    const policy = await this.consulApi.createPolicy(
      {
        Name: `${key}_${right}`,
        Rules: `key_prefix "${key}" {
  policy = "${right}"
}`
      },
      this.extData.superAdminToken
    )
    const res = await this.consulApi.createToken(
      {
        Description: `${key}管理token`,
        Policies: [
          {
            ID: (policy as any).data.ID
          }
        ],
        Local: false
      },
      this.extData.superAdminToken
    )
    this.extData[`${key}_${right}`] = res.data.SecretID
    this.saveExtData()
    return res.data.SecretID
  }

  /** 保存实例扩展数据 */
  saveExtData () {
    if (this.instance.id) {
      appInstanceApi.saveInstanceExtData(this.instance.id, this.extData)
    }
  }

  /** 获取实例扩展数据 */
  async getExtData () {
    if (this.instance.id) {
      const re = await appInstanceApi.queryInstanceExtData(this.instance.id)
      this.extData = re.data
      this.readToken = this.extData[`${this.key}_read`]
      this.writeToken = this.extData[`${this.key}_write`]
    }
  }
}
