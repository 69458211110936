import { showModal } from '@/libs/modal-helper'
import modal from './init-consul-modal.vue'
import { AppInstance } from '@/api/maintain/app-instance'

/**
  * 添加用户或用户组对话框
  * @returns
  */
export default function showInitConsulModal (instance: AppInstance) {
  return new Promise<void>((resolve, reject) => {
    showModal<void>(modal, {
      props: {
        instance: instance
      }
    }, true, () => {
      resolve()
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
